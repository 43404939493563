import React from 'react';
import { createUseStyles } from 'react-jss';
import VideosSection from './Videos';
import ArticlesSection from './Articles';
import PageHeader from '../Shared/PageHeader';

function ResourcesPage(){
    const styles = useStyles();
    return(
        <div className={styles.pageContainer}>
            <div className={styles.contactImageHeader}>
               <PageHeader pageTitle="Resources" />
            </div>
            <div className={styles.articlesContainer}>
                <ArticlesSection />
            </div>
            <div className={styles.videosContainer}>
                <VideosSection />
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    pageContainer: {
        position: "relative",
        minWidth: "100vw",
        height: "100%",
        width: "100%",
        fontFamily: "Open Sans, sans-serif",
        display: "flex",
        flexDirection: " column"
    },
    articlesContainer:{
        minHeight: "40vh",
        marginTop: "3%"
    },
    videosContainer:{
        minHeight: "40vh",
        background: "#eaebec",
    }
})

export default ResourcesPage;
