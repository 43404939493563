import React from 'react';
import { createUseStyles } from 'react-jss';
import PageHeader from '../Shared/PageHeader'
import WhoAreWeSection from './WhoAreWe';
import WhatWeDoSection from './WhatWeDo';
import MissionSection from './OurMission';
// import OurTeamSection from './OurTeam';

function AboutPage(){
    const styles = useStyles();
    return(
        <div className={styles.pageContainer}>
            <div className={styles.aboutImageHeader}>
               <PageHeader pageTitle="About Us" />
            </div>
            <div className={styles.whoSection}>
                <WhoAreWeSection />
            </div>
            <div className={styles.missionSection}>
                <MissionSection />
            </div>
            <div className={styles.whatSection}>
                <WhatWeDoSection />
            </div>
            {/* <div className={styles.teamSection}>
                <OurTeamSection />
            </div> */}
        </div>
    )
} 

const useStyles = createUseStyles({
    pageContainer: {
        position: "relative",
        height: "100%",
        width: "100%",
        fontFamily: "Open Sans, sans-serif",
        display: "flex",
        flexDirection: " column"
    },
    whoSection:{
        minHeight: "30vh",
    },
    whatSection:{
        minHeight: "45vh",
        background: "#eaebec",
    },
    missionSection:{
        minHeight: "35vh",
    },
    teamSection:{
        minHeight: "45vh",
        background: "#122B3E",
    },
})

export default AboutPage
