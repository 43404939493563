import React from 'react';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone, faEnvelope,  } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';


function Footer({ google }){
    const styles = useStyles();
    return(
        <>
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.itemContainer}>
                    <div className={styles.Header}>
                        <h3 className={styles.companyHeaderText}>Integrated Holistic Psychotherapy</h3>
                    </div>
                    <div className={styles.companyDescriptionContainer}>
                        <h5 className={styles.companyDescriptionText}>Integrated Holistic Psychotherapy is a Consultancy and Counseling firm specialized in offering quality services in a safe, confidential and supportive environment</h5>
                    </div>
                    <div className={styles.contactContainer}>
                        <div className={styles.iconsContainer}>
                            <FontAwesomeIcon size="xs" icon={faPhone} />
                            <p className={styles.iconText}>+254729569381 | +254784751166</p>
                        </div>
                        <div className={styles.iconsContainer}>
                            <FontAwesomeIcon size="xs" icon={faEnvelope} />
                            <p className={styles.iconText}><a className={styles.email} href="mailto:ihpsy18@gmail.com">ihpsy18@gmail.com</a></p>
                        </div>
                        <div className={styles.iconsContainer}>
                            <FontAwesomeIcon size="xs" icon={faMapMarker} />
                            <p className={styles.iconText}>Doctors Plaza, Ruiru Ground Floor, Room F06</p>
                        </div>
                    </div>
                    <div className={styles.socialsContainer}>
                        <div className={styles.socials}>
                        <   FontAwesomeIcon icon={faFacebook} color="#2D88FF" />
                        </div>
                        <div className={styles.socials}>
                            <FontAwesomeIcon icon={faTwitter} color="#45AEF4" />
                        </div>
                        <div className={styles.socials}>
                            <FontAwesomeIcon icon={faYoutube} color="#FF0100" />
                        </div>
                    </div>
                </div>
                <div className={styles.itemContainer}>
                    <div className={styles.Header}>
                        <h3 className={styles.HeaderText}>Quick Links</h3>
                    </div>
                    <div className={styles.linksContainer}>
                        <ul className={styles.linksList}>
                            <li>
                                About Us
                            </li>
                            <li>
                                Events
                            </li>
                            <li>
                                Gallery
                            </li>
                            <li>
                                Blog | Videos
                            </li>
                            <li>
                                Contact Us
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={[styles.itemContainer]}>
                <div className={styles.Header}>
                        <h3 className={styles.HeaderText}>Location</h3>
                    </div>
                    <div className={styles.map}>
                    <Map 
                        style={{ height: '300px'}}
                        zoom={19}
                        initialCenter={{
                            lat: -1.146683,
                            lng: 36.957940
                            }} 
                        google={google}
                    >
                        <Marker 
                        title="Location"
                        name={"Location"} 
                        position={{
                            lat: -1.146683,
                            lng: 36.957940
                            }}  />
                    </Map>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.copyrightContainer}>
            <p className={styles.copyrightText}>Integrated Holistic Psychotherapy © 2020</p>
        </div>
        </>
        
    )
}

const useStyles = createUseStyles({
    map:{
        height: 330, 
        position: 'relative'
    },
    container:{
        padding: "2%",
        color: "white",
        backgroundColor: "black",
    },
    contentContainer: {
        display: "flex",
        maxWidth: "80%",
        margin: "auto"
    },
    itemContainer: {
        position:"relative",
        flexDirection: "column",
        margin: "0 auto",
        width: "30%",
        height: "100%",
        alignItems: "center"
    },
    Header: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    companyHeaderText:{
        font: {
            size: 16
        }
    },
    HeaderText:{
        font: {
            size: 16
        }
    },
    companyDescriptionContainer:{
        display: "flex",
        flexWrap: "wrap",
        alignSelf: "center",
    },
    companyDescriptionText:{
        font: {
            size: 12,
            weight: 400
        }
    },
    copyrightContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#3C404D",
        margin: "auto",
        color: "white"
    },
    copyrightText:{
        margin: 10,
        font:{
            size: 12
        }
    },
    contactContainer:{
        display: "flex",
        marginTop: 15,
        flexDirection:"column",
        width: "100%",
    },
    iconsContainer: {
        display: "flex",
        maxHeight: 25,
        alignItems: "center",
    },
    iconText:{
        marginLeft: "5%",
        font:{
            size: 12
        }
    },
    email: {
        color: "white",
	    textDecoration: "none"
    },
    socialsContainer:{
        display: "flex",
        flexDirection:"row",
        width: "100%",
        margin: "5% 0"
    },
    socials:{
        margin: 3
    },
    linksContainer: {
        width: "100%",
        display: "flex",
    },
    linksList:{
        listStyleType: "none",
        listStylePosition:"inside",
        margin: 0,
        padding:0,
    },
    '@media (max-width: 500px)': {
        contentContainer: {
            display: "flex",
            flexDirection: "column",
        },
        itemContainer: {
            width: "80%",
            margin: "5px auto",
            alignItems: "center",
            textAlign: "center"
        },
        linksList:{
            display: "flex",
            height: 30,
            width: "80%",
            flexDirection: "row",
            flexWrap:"wrap",
            justifyContent: "space-evenly",
            alignContent: "space-between",
            font: {
                size: 10,
                weight: 300
            }
        },
        socialsContainer:{
            justifyContent: "center"
        },
        iconsContainer: {
            alignItems: "flex-start"
        },
        iconText:{
            font: {
                size: 10,
                weight: 300
            } 
        },
        Header:{
            justifyContent:"center"
        },
        linksContainer: {
            justifyContent: "center"
        }
    },
})

export default GoogleApiWrapper({
    apiKey: ("AIzaSyA2Q9H9x3CBoCa9Jq-ztbV8_IEn--kFS7U")
   })(Footer);
