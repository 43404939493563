import React, {useState} from 'react';
import { createUseStyles } from 'react-jss';
import { counties } from './counties';
import { services } from './services';
import axios from 'axios';

function ContactSection(){
    const styles = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [county, setCounty] = useState('');
    const [service, setService] = useState('');
    const [contactType, setContactType] = useState('');
    const [message, setMessage] = useState('');

    const disabled = !(name && email && phoneNumber && county && service && message);

    const url = `${process.env.REACT_APP_SCHEDULE_API}/schedule`

    const handleSubmit = (e) => {
        e.preventDefault();
        const requestBody = { 
            eventDetails: {
                summary: `${service} services: ${name}`,
                description: `This is a meeting scheduled by ${name} for ${service} services.<br/><br/>Phone: ${phoneNumber}<br/>Email: ${email}<br/>County: ${county}<br/><br/>Message: ${message}`,
                colorId: "2",
                timeZone: "Africa/Nairobi",
                reminders:{
                    useDefault: false,
                    overrides: [
                        { method: 'email', minutes: 24 * 60 },
                        { method: 'popup', minutes: 30 }
                    ]
                }
            },
            calendarDetails: {
                id: "ihpsy18@gmail.com",
                senderName: "Intergrated Holistic Psychotherapy"
            },
            recipientDetails: {
                recipientEmail: email,
                recipientName: name,
                subject: `INHOP: ${service} services`
            }
        };

        if(contactType === "Schedule"){
            axios({
                url: url, 
                method: 'POST',
                credentials: 'include',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin':'*'
                },
                data: requestBody
              }).then(() => {
                    setName('');
                    setEmail('');
                    setPhoneNumber('')
                    setCounty('')
                    setService('')
                    setContactType('')
                    setMessage('')
                    console.log("@@Success!");
              }).catch( error => {
                    console.log("<== Error ==>", error);
              });
        }
    }

    return(
        <div className={styles.container}>
             <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>Contact Us</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div className={styles.contactContentContainer}>
                <div className={styles.formContainer}>
                    <form name="contact" className={styles.form} onSubmit={handleSubmit}>
                        <div className={styles.topFormContent}>
                            <div className={styles.formInputContainer}>
                                <label>Name</label>
                                <input name="name" className={styles.formInput} type="text" placeholder="Name e.g John Doe" value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className={styles.formInputContainer}>
                                <label>Email</label>
                                <input name="email" className={styles.formInput} type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.topFormContent}>
                            <div className={styles.formInputContainer}>
                                <label>Phone Number</label>
                                <input name="phone-number" className={styles.formInput} type="text" placeholder="Format: +2547********" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            </div>
                            <div className={styles.formInputContainer}>
                                <label>County of Residence</label>
                                <input name="county" list="counties" className={styles.formInput} type="text" placeholder="Pick your county" value={county} onChange={(e) => setCounty(e.target.value)} />
                                <datalist id="counties">
                                    {
                                        counties.map((county, index) => {
                                            return(
                                                <option value={county} key={index} />
                                            )
                                        })
                                    }
                                </datalist>
                            </div>
                        </div>
                        <div className={styles.topFormContent}>
                            <div className={styles.formInputContainer}>
                            <label>Service</label>
                                <input name="service" list="services" className={styles.formInput} type="text" placeholder="Pick a service" value={service} onChange={(e) => setService(e.target.value)}/>
                                <datalist id="services">
                                    {
                                        services.map((service, index) => {
                                            return(
                                                <option value={service} key={index} />
                                            )
                                        })
                                    }
                                </datalist>
                            </div>
                            <div className={styles.formInputContainer}>
                                <label>Contact Type</label>
                                <input name="contactType" list="contactTypes" className={styles.formInput} type="text" placeholder="Contact Type" value={contactType} onChange={(e) => setContactType(e.target.value)} />
                                <datalist id="contactTypes">
                                    <option value="Inquiry" />
                                    <option value="Schedule" />
                                </datalist>
                            </div>
                        </div>
                        <div className={styles.bottomFormContent}>
                            <div className={styles.formTextAreaContainer}>
                                <label>Message</label>
                                <textarea name="message" className={styles.formTextArea} type="text" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <button type="submit" className={styles.button} disabled={disabled}>Submit</button>
                        </div>
                    </form>

                </div>
                <div className={styles.contactFooterContainer}>
                    <div className={styles.itemContainer}>
                        <div className={styles.itemHeader}>
                            <div>
                                <h3 className={styles.contactSectionTitle}>Address</h3>
                                <div className={styles.contactSectionUnderline} />
                            </div>
                            <div className={styles.itemDetails}>
                                <h5 className={styles.itemDetailsText}>Ruiru (Doctors Plaza) Ground Floor</h5>
                                <h5 className={styles.itemDetailsText}>Room number F06.</h5>
                            </div>
                        </div>    
                    </div>
                    <div className={styles.itemContainer}>
                        <div className={styles.itemHeader}>
                            <div>
                                <h3 className={styles.contactSectionTitle}>Working Hours</h3>
                                <div className={styles.contactSectionUnderline} />
                            </div>
                            <div className={styles.itemDetails}>
                                <h5 className={styles.itemDetailsText}>Monday - Saturday: 0800 - 1800</h5>
                                <h5 className={styles.itemDetailsText}>Sunday: Closed</h5>
                            </div>
                        </div>    
                    </div>
                    <div className={styles.itemContainer}>
                        <div className={styles.itemHeader}>
                            <div>
                                <h3 className={styles.contactSectionTitle}>Contact Info</h3>
                                <div className={styles.contactSectionUnderline} />
                            </div>
                            <div className={styles.itemDetails}>
                                <h5 className={styles.itemDetailsText}>Phone: +254729569381</h5>
                                <h5 className={styles.itemDetailsText}>Email: ihpsy18@gmail.com</h5>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container:{
        height: "100%",
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "2%"
    },
    sectionTitle:{
        font: {
            size: 28,
        },
        margin: 10
    },
    contactSectionTitle:{
        font: {
            size: 18,
        },
        margin: 10
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 90,
        margin: "auto"
    },
    contactSectionUnderline:{
        borderBottom:"4px solid green",
        width: 60,
        margin: "auto"
    },
    contactContentContainer:{
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "70%"
    },
    contactFooterContainer:{
        marginTop: "5%",
        display: "flex",
        justifyContent: "space-evenly"
    },
    itemContainer:{
        width: "33%",
        height: 180,
        display:"flex",
        justifyContent:"center",
    },
    itemHeader:{
        display: "flex",
        height: 100,
        flexDirection:"column",
        alignItems: "center",
    },
    itemDetails:{
        textAlign: "center",
        marginTop: 15
    },
    itemDetailsText:{
        textAlign: "center",
        font: {
            size: 14,
            weight: 400
        }
    },
    form:{
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    topFormContent:{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        margin: 10
    },
    bottomFormContent:{
        width: "100%",
        margin: 10
    },
    formInputContainer:{
        display: "flex",
        width: "45%",
        flexDirection: "column"
    },
    formInput:{
        padding: "12px 20px",
        margin: "8px 0",
        display: "inline-block",
        border: "1px solid #ccc",
        borderRadius:" 4px",
        boxSizing: "border-box"
    },
    formTextAreaContainer:{
        display: "flex",
        width: "100%",
        flexDirection: "column"
    },
    formTextArea:{
        height: 100,
        padding: "12px 20px",
        margin: "8px 0",
        display: "inline-block",
        border: "1px solid #ccc",
        borderRadius:" 4px",
        boxSizing: "border-box"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        width: "70%",
        margin: "auto",
    },
    button: {
        display: "inline-block",
        backgroundColor:"#3b86ff",
        alignSelf: "center",
        color: "white",
        textDecoration: "none",
        textAlign: "center",
        borderRadius: 3,
        padding:"10px 25px",
        border: "none",
        font:{
            size: 16
        },
        height: 50,
        '&:hover':  {
            backgroundColor: "white",
            color:"#3b86ff",
            border: "2px solid #3b86ff"
        },
        '&:focus': {
            outline: 0
        }
    },
    '@media (max-width: 500px)': {
        contactFooterContainer:{
            marginTop: "10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly"
        },
        itemContainer:{
            width: "100%"
        },
        itemHeader: {
            height: "100%",
        },
        topFormContent:{
           flexDirection: "column"
        },
        formInputContainer:{
            width: "100%",
        },
    },
})

export default ContactSection;
