export const services = [
    {
        name:"Individual Counselling",
        image:{
            src:"https://res.cloudinary.com/doosfxptt/image/upload/v1593695381/inhop/individualCounselling_s4avjo.jpg",
            alt: "individual counselling"
        },
        description:"Most people get a cathartic experience by venting out some of their pent up feelings and emotions to someone who will listen without judging them. Sharing with a professional counsellor hence enables them to view their thoughts from a different perspective which helps them cope and get along with their lives. Book a session with us."
    },
    {
        name:"Couple & Family Counselling",
        image:{
            src:"https://res.cloudinary.com/doosfxptt/image/upload/v1593698625/inhop/psychology-family-therapy_juyyaq.jpg",
            alt: "family-and-couple-counselling"
        },
        description:"Taking time to talk through challenges that may come up in marriage with a neutral third party is benefitial. It enables both sides to air their views and a cousellor helps them to see arguments from each other's perspective. That's why we are here, book a session with a marriage therapist."
    },
    {
        name:"Group Counselling",
        image:{
            src:"https://res.cloudinary.com/doosfxptt/image/upload/v1593696274/inhop/clip-art-group_wvordi.jpg",
            alt: "group-counselling"
        },
        description:"We normally facilitate group counselling sessions to various individuals in groups facing the same challenges. Groups offer numerous advantages that may appeal to every individual. In most cases, members get an opportunity to work on improving their lives in a supportive, conducive and structured environment. This gives them a sense of belonging and improves their social skills and much more. We therefore encourage people to join groups with us for psycho-social support."
    },
    {
        name:"Teen Mentorship",
        image:{
            src:"https://res.cloudinary.com/doosfxptt/image/upload/v1593697110/inhop/teen-clipart_wdmjuo.jpg",
            alt: "teen-mentorship"
        },
        description:"At INHOP, we offer mentorship for young people because we have seen the strategy working towards building healthy and stronger relationships with the teen's parents, teachers and their fellow peers. It also enhances self esteem and self-confidence, improves inter-personal skills and behaviour change both at home and in school. This in turn helps to lower school dropout rates, reduces teenage pregnancies and decreases likelihood of young people getting into drugs and substance use and also getting mental health illnesses. We therefore encourage parents to pay for mentorship for their kids. Apparently, we are offering virtual mentorship sessions due to COVID-19 pandemic. Feel free to get in touch with us for more information."
    },
    {
        name:"Mediation",
        image:{
            src:"https://res.cloudinary.com/doosfxptt/image/upload/v1593698340/inhop/mediation-clipart_lljqqq.jpg",
            alt: "mediation"
        },
        description:"We normally offer mediation services due to various reasons amongst them to restore peace to parties who may be having conflicts by giving them an opportunity to solve their disagreements amicably without the need of going to court. That is why we have Accredited Mediators who ensure there is fairness through the process and offer an impartial ground for individuals to express themselves until they get to an agreement."
    },
    {
        name:"Psycho-education & Psychiatric Review Services",
        image:{
            src:"https://res.cloudinary.com/doosfxptt/image/upload/v1593708019/inhop/clip-art-Psychiatrist_xhyzzl.jpg",
            alt: "psychoeducation-and-psychiatric review"
        },
        description:"As our mission states, we integrate various approaches to promote the mental well being of our clients. One of the ways in which we achieve this is through working with different mental health practitioners who also facilitate during psychoeducation sessions which are an essential aspect of an effective therapy program. Psychoeducation is benefitial not only to the individual diagnozed  with a mental condition but also to their parents, other family members, friends and caregivers. A Counselling Psychologist offers therapy to enable the individual to cope with the condition while a Psychiatrist explains how a prescribed medication can counteract symptomps of a mental health condition. It is due to these reasons and many more that we also offer Psychiatric review services besides counselling services. Incase you may need to see a Psychiatrist, feel free to book an appointment with us."
    },
]
