import React from 'react';
import { createUseStyles } from 'react-jss';

function WhoAreWePage(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
            <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>Who We Are</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                    <div className={styles.descriptionSection}>
                        <p className={styles.descriptionText}><strong className={styles.companyName}>Integrated Holistic Psychotherapy</strong> is a Consultancy and Counseling firm specialized in offering quality services in a 
                        safe, confidential and supportive environment for individuals, couples, families and corporate groups with caring 
                        professional mental health practitioners to receive the healing and restoration you need.
                        </p>
                    </div>
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container:{
        height: "100%",
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        width: "70%",
        margin: "auto",
        marginTop: "3%",
        alignItems: "center",
    },
    sectionTitle:{
        font: {
            size: 30,
        },
        margin: "10px 0"
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 150,
    },
    contentContainer:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "70%",
        minHeight: "20vh",
        margin: "auto",
    },
    descriptionSection:{
        width: "65%",
    },
    descriptionText:{
        font: {
            size: 18,
            weight: 400
        }
    },
    companyName:{
        font: {
            size: 20,
        }
    },
    '@media (max-width: 500px)': {
        contentContainer:{
            flexDirection: "column",
        },
        descriptionSection:{
            width: "100%",
        },
        sectionTitle:{
            font: {
                size: 28,
            },
            margin: "10px 0"
        },
        descriptionText:{
            font: {
                size: 16,
                weight: 400
            }
        },
        sectionUnderline:{
            width: 100,
        },
    },
})

export default WhoAreWePage