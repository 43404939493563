import React from 'react';
import { ThemeProvider, createUseStyles } from 'react-jss'
import Services from './Services';
// import Testimonials from './Testimonials';
import Partnerships from './Partnerships';
import Schedule from './Schedule';
import PageHeader from '../Shared/PageHeader';
import WhoAreWeSection from '../AboutPage/WhoAreWe';
import MissionSection from '../AboutPage/OurMission';

const theme = {

}

const carouselImages = [
  {
    src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589998663/inhop/IMG_20191201_153536_amedo9.jpg",
    alt: "inhop"
  },
  {
    src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589999457/inhop/IMG-20191106-WA0065_xkrakp.jpg",
    alt: "inhop"
  },
  {
      src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589999748/inhop/IMG_20191216_133411_nfpvm1.jpg",
      alt: "inhop"
  },
  
]

function HomePage() {
  const styles = useStyles({theme})
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <div className={styles.landingContainer}>
        <PageHeader pageTitle="Integrated Holistic Psychotherapy" main images={carouselImages} />
      </div>
      <div className={styles.whoSection}>
        <WhoAreWeSection />
      </div>
      <div className={styles.missionSection}>
        <MissionSection />
      </div>
      <div className={styles.servicesContainer}>
        <Services />
      </div>
      {/* <div className={styles.testimonialsContainer}>
        <Testimonials />
      </div> */}
      <div className={styles.partnershipsContainer}>
        <Partnerships />
      </div>
      <div className={styles.scheduleContainer}>
          <Schedule />
      </div>
    </div>
    </ThemeProvider>
  );
}

const useStyles = createUseStyles({
  pageContainer: {
    position: "relative",
    minHeight: "100vh",
    minWidth: "100vw",
    height: "100%",
    width: "100%",
    fontFamily: "Open Sans, sans-serif",
    display: "flex",
    flexDirection: " column"
  },
  landingContainer:{
    minHeight: "75vh",
  },
  whoSection:{
    minHeight: "30vh",
  },
  missionSection:{
    minHeight: "35vh",
  },
  servicesContainer:{
    minHeight: "40vh",
    background: "#eaebec",
  },
  testimonialsContainer: {
    background: "#122B3E",
  },
  partnershipsContainer:{
    background: "#122B3E",
    color: "white",
    minHeight: "40vh",
  },
  scheduleContainer:{
    minHeight: "40vh",
  }
})

export default HomePage;
