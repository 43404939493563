import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars  } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../Assets/images/logo.jpg'

function NavBar(){
    const styles = useStyles();
    const [collapse, setCollapse] = useState(false);
    return(
        <div>
            <div className={styles.container}>
                <div className={styles.navSection}>
                    <div className={styles.logoContainer}>
                        <img className={styles.logo} src={Logo} alt="logo"/>
                    </div>
                    <div className={styles.menuContainer}>
                        <ul className={styles.menu}>
                            <li ><Link className={styles.navItem} to="/">Home</Link></li>
                            <li ><Link className={styles.navItem} to="/about">About Us</Link></li>
                            <li ><Link className={styles.navItem} to="/resources">Resources</Link></li>
                            <li ><Link className={styles.navItem} to="/gallery">Gallery</Link></li>
                            <li ><Link className={styles.navItem} to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className={styles.hambugerMenuContainer}>
                        <FontAwesomeIcon size="2x" icon={faBars} onClick={() => { setCollapse(!collapse) }} />
                    </div>
                </div>
                {collapse && <div className={styles.hambuger}>
                    <ul className={styles.hamburgerMenu}>
                        <li ><Link className={styles.navItem} onClick={() => { setCollapse(false)}} to="/">Home</Link></li>
                        <li ><Link className={styles.navItem} onClick={() => { setCollapse(false)}} to="/about">About Us</Link></li>
                        <li ><Link className={styles.navItem} onClick={() => { setCollapse(false)}} to="/resources">Resources</Link></li>
                        <li ><Link className={styles.navItem} onClick={() => { setCollapse(false)}} to="/gallery">Gallery</Link></li>
                        <li ><Link className={styles.navItem} onClick={() => { setCollapse(false)}} to="/contact">Contact Us</Link></li>
                    </ul>
                </div>}
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    navSection:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0"
    },
    container:{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: "0 10px"
    },
    menuContainer:{
        minWidth: 700
    },
    menu:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        listStyleType: "none"
    },
    navItem:{
        color: "inherit",
        textDecoration: "none"
    },
    hambugerMenuContainer:{
        display: "none"
    },
    hambuger:{
        display: "none"
    },
    hamburgerMenu:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems:"center",
        listStyleType: "none",
        padding: 0,
        margin: "10px auto"
    },
    logo:{
        width: 100,
        height: 75
    },
    '@media (max-width: 500px)': {
        menuContainer:{
            display: "none"
        },
        hambugerMenuContainer:{
            display: "flex"
        },
        hambuger:{
            display: "flex",
            width: "100%"
        },
    },
})

export default NavBar;
