import React from "react";
import { createUseStyles } from "react-jss";
import PageHeader from '../Shared/PageHeader';
import PhotoSection from './Photo';

function GalleryPage(){
    const useStyles = createUseStyles({
        pageContainer: {
            position: "relative",
            height: "100%",
            width: "100%",
            fontFamily: "Open Sans, sans-serif",
            display: "flex",
            flexDirection: " column"
        },
        photoSection:{
            minHeight: "75vh",
            background: "#eaebec",
        }
    });
    const styles = useStyles();
    return(
        <div className={styles.pageContainer}>
            <div className={styles.aboutImageHeader}>
               <PageHeader pageTitle="Gallery" />
            </div>
            <div className={styles.photoSection}>
                <PhotoSection />
            </div>
        </div>
    )
}


export default GalleryPage;
