import React from 'react';
import { createUseStyles } from 'react-jss';

function Partnerships(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
        <div className={styles.sectionHeader}>
            <div>
                <h3 className={styles.sectionTitle}>Our Partners</h3>
                <div className={styles.sectionUnderline} />
            </div>
        </div>
        <div className={styles.contentContainer}>
                <div className={styles.descriptionSection}>
                    <p className={styles.descriptionText}>
                    We are open to work with any registered groups or organisations that we share the same vision with.
                    </p>
                </div>
        </div>
    </div>

    )
}

const useStyles = createUseStyles({
    container:{
        height: "100%",
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        justifyContent: "center",
        alignItems: "center",
    },
    contentContainer:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "70%",
        minHeight: "15vh",
        margin: "auto",
    },
    sectionTitle:{
        font: {
            size: 28,
        },
        margin: 10
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 90,
        margin: "auto"
    },
    descriptionText:{
        textAlign: "center",
        font: {
            size: 16
        }
    },
    '@media (max-width: 500px)': {
        descriptionText:{
            textAlign: "center",
            font: {
                size: 12
            }
        },
    }

})

export default Partnerships;
