import React from 'react';
import { createUseStyles } from 'react-jss';
import { videos } from './videos';

function VideosSection(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
             <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>Videos</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                {videos.map((video, index) => {
                    const { src } = video
                    return(
                        <div key={index} className={styles.itemContainer}>
                           <iframe 
                            src={src}
                            frameBorder='0'
                            width={325}
                            height={250}
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            title='video'
                           />
                        </div>
                    )
                })}

            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container:{
        color: "black",
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        justifyContent: "center",
        alignItems: "center",
    },
    sectionTitle:{
        font: {
            size: 28,
        },
        margin: 10
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 60,
        margin: "auto"
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "80%",
        maxWidth: 900,
        margin: "auto",
        padding: "3%"
    },
})

export default VideosSection;
