export const photos = [
    {
        alt:"",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589998885/inhop/IMG_20191201_154015_cjqikh.jpg"
    },
    {
        alt:"",
        src:"https://res.cloudinary.com/doosfxptt/image/upload/v1589995338/inhop/IMG-20200303-WA0014_k6tpko.jpg"
    },
    {
        alt:"",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589995341/inhop/IMG-20191106-WA0065_n7kqcr.jpg"
    },
    {
        alt:"",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589999305/inhop/IMG_20191201_154043_npvrfg.jpg"
    },
    {
        alt:"",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1590000128/inhop/IMG_20191217_122742_yq3vvi.jpg"
    },
    {
        alt:"",
        src:"https://res.cloudinary.com/doosfxptt/image/upload/v1590000302/inhop/IMG_20191217_120506_mqdgyp.jpg"
    },
    {
        alt: "",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589999419/inhop/IMG_20191216_133207_npac1h.jpg"
    },
    {
        alt: "",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589999748/inhop/IMG_20191216_133411_nfpvm1.jpg"
    },
    {
        alt: "",
        src: "https://res.cloudinary.com/doosfxptt/image/upload/v1589998178/inhop/IMG_20191129_164930_tpr1nu.jpg"
    },
    {
        "alt":"",
        src:"https://res.cloudinary.com/doosfxptt/image/upload/v1589995693/inhop/IMG_20191130_174114_il7wdd.jpg"
    },
    {
        "alt":"",
        src:"https://res.cloudinary.com/doosfxptt/image/upload/v1589998309/inhop/IMG_20191201_152946_beameh.jpg"
    },
    {
        "alt":"",
        src:"https://res.cloudinary.com/doosfxptt/image/upload/v1589995538/inhop/IMG_20191130_174637_zvkbav.jpg"
    }
]
