import React from 'react';
import { createUseStyles } from 'react-jss';
import Background from '../../Assets/images/home-background.JPG';
import Carousel from 'react-bootstrap/Carousel';

function PageHeader({pageTitle, src, alt, main, images}){
    const useStyles = createUseStyles({
        container:{
            height: "100%",
            width: "100%", 
        },
        landingContainer:{
            display: "flex",
            justifyContent: "center",
            alignItems: main ? "flex-start" : "initial",
            minHeight: "75vh",
            backgroundImage:` url(${Background})`,
            backgroundSize: "cover",
            backgroundColor: "rgb(48, 37, 32, 0.5)",
            backgroundBlendMode: "darken"
        },
        pageTitleContainer:{
            width: "70%",
            maxWidth: 800,
            margin: main ? "140px 0 0" : "auto"
        },
        pageTitle:{
            margin: 5,
            font:{
                size: 70
            },
            textAlign: "center",
            color: "white"
        },
        sectionUnderline:{
            borderBottom:"4px solid green",
            width: 150,
            margin: "auto"
        },
        '@media (max-width: 500px)': {
            pageTitle:{
                margin: 5,
                font:{
                    size: 35
                },
                textAlign: "center",
                color: "white"
            },
        },
    })
    const styles = useStyles();
    return(
        <div className={styles.container}>
            {main ? 
            (
                <div>
                    <Carousel
                      interval={3000}
                    >
                    {images.map((image, index) => {
                        const { src, alt } = image;
                        return(
                            <Carousel.Item key={index}>
                                <img
                                className="d-block w-100"
                                style={{
                                    objectFit: "cover",
                                    height: 700

                                }}
                                src={src}
                                alt={alt}
                                
                                />
                                <Carousel.Caption>
                                    <h3 className={styles.pageTitle}>{pageTitle}</h3>
                                    <blockquote className={styles.motto}>"<strong>Transforming Lives</strong>"</blockquote>
                                </Carousel.Caption>
                            </Carousel.Item>
                        );
                })}  

                    </Carousel>

                </div>
            ):(
            <div className={styles.landingContainer}>
                <div className={styles.pageTitleContainer}>
                    <div>
                        <h1 className={styles.pageTitle}>{pageTitle}</h1>
                        <div className={styles.sectionUnderline} />
                    </div>
                </div>
            </div>)}
        </div>
    )
} 

export default PageHeader;
