import React from 'react';
import { createUseStyles } from 'react-jss';

function ArticlesSection(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
            <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>Articles</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                <h5>Articles are coming soon.</h5>
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container: {
        color: "black",
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        justifyContent: "center",
        alignItems: "center",
    },
    sectionTitle:{
        font: {
            size: 28,
        },
        margin: 10
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 60,
        margin: "auto"
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "80%",
        maxWidth: 900,
        margin: "auto"
    },
})

export default ArticlesSection;
