import React from 'react';
import { Switch, Route } from "react-router-dom";
import { ThemeProvider, createUseStyles } from 'react-jss'
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import ResourcesPage from './ResourcesPage';
import ContactUsPage from './ContactUsPage';
import GalleryPage from './Gallery';

import NavBar from '../src/Shared/NavBar';
import Footer from '../src/Shared/Footer';


const theme = {};
function App() {
  const styles = useStyles({theme})
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.headerContainer}>
        <NavBar />
      </div>
      <div className={styles.pageContent}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/resources" component={ResourcesPage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/contact" component={ContactUsPage} />
        </Switch>
      </div>
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

const useStyles = createUseStyles({
  headerContainer: {
    width: "100vw",
    minHeight: 70,
  },
  pageContent:{
    minHeight: "70vh"
  },
  footerContainer: {
    width: "100vw",
    height: "fit-content",
  },
})

export default App;
