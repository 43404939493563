import React from 'react';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus  } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router';

function ScheduleSection({history}){
    const styles = useStyles();
    return(
        <div className={styles.container}>
           <div className={styles.contentContainer}>
           <div className={styles.calender}>
                <FontAwesomeIcon icon={faCalendarPlus} color={"#49a0d5"} size="4x" />
            </div>
            <div className={styles.sectionHeaher}>
                <h3 className={styles.sectionText}>Would you like to book an Appointment? </h3>
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.button} onClick={() =>  { window.scrollTo(0, 0); history.push('/contact')} }>Schedule Appointment</button>
            </div>
           </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 400
    },
    calender:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    sectionHeaher:{
        display: "flex",
        height: 100,
        width: "80%",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        width: "70%",
        margin: "auto",
    },
    button: {
        display: "inline-block",
        backgroundColor:"#3b86ff",
        alignSelf: "center",
        color: "white",
        textDecoration: "none",
        textAlign: "center",
        borderRadius: 3,
        padding:"10px 25px",
        border: "none",
        font:{
            size: 16
        },
        height: 50,
        '&:hover':  {
            backgroundColor: "white",
            color:"#3b86ff",
            border: "2px solid #3b86ff"
        },
        '&:focus': {
            outline: 0
        }
    },
    sectionText:{
        textAlign: "center",
        font: {
            size: 20
        }
    },
    '@media (max-width: 500px)': {
        sectionText:{
            font: {
                size: 18
            }
        },
    }
})

export default withRouter(ScheduleSection);
