import React from 'react';
import { createUseStyles } from 'react-jss';

function WhatWeDo(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
             <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>What We Do</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div>
                <div className={styles.contentContainer}>
                        <div className={styles.descriptionSection}>
                            <p className={styles.descriptionText}><strong className={styles.companyName}>We </strong>
                            offer psychological counseling services to individuals who could be facing the following but not limited to:
                            </p>
                            <ul>
                                <li>Life transition challenges</li>
                                <li>Relationship issues </li>
                                <li>Stress or depression </li>
                                <li>Anxiety or any other Disorder </li>
                                <li>Those who have various addictions </li>
                                <li>Suicidal ideation </li>
                                <li>Marriage and family issues</li>
                                <li>Loss and grief </li>
                                <li>Trauma (physical, emotional or sexual).</li>
                            </ul>
                        </div>
                        <div className={styles.imageSection}>
                            <div className={styles.othersSectionHeader}>
                                <div className={styles.otherSection}>
                                    <h3 className={styles.othersSectionTitle}>Other Services</h3>
                                    <div className={styles.otherSectionUnderline} />
                                </div>
                            </div>
                            <div>
                                <p className={styles.otherText}>
                                Other additional intensive services that we offer include:
                                </p>
                                <ul>
                                    <li>Psychoeducation </li>
                                    <li>Critical incidence debriefing</li>
                                    <li>Motivational talks</li>
                                    <li>Mentorship for Adolescents and youth</li>
                                    <li>supervision for counselors </li>
                                    <li>Psychological assessments</li>
                                    <li>Career screening and guidance</li>
                                </ul>
                            </div>
                        </div>
                </div>
                <div className={styles.groups}>
                    <p className={styles.otherText}>
                    <strong>We</strong> offer the above Consultancy services to various groups in:
                    </p>
                    <ul>
                        <li>Churches</li>
                        <li>Schools</li>
                        <li>Institutions of Learning</li>
                        <li>Workplaces</li>
                        <li>Community Level</li>
                        <li>Colleges and Universities</li>
                    </ul>
                </div>
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container:{
        height: "100%",
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        width: "70%",
        margin: "auto",
        marginTop: "3%",
        alignItems: "center",
    },
    sectionTitle:{
        font: {
            size: 36,
        },
        margin: "10px 0"
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 150,
    },
    contentContainer:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "70%",
        minHeight: "20vh",
        margin: "auto",
    },
    groups:{
        width: "70%",
        margin: "0 auto 4% auto",
    },
    descriptionSection:{
        width: "55%",
    },
    descriptionText:{
        width: "85%",
        font: {
            size: 16,
            weight: 400
        }
    },
    companyName:{
        font: {
            size: 20,
        }
    },
    imageSection: {
        width: "40%",
        minHeight: "20vh",
    },
    othersSectionHeader:{
        display: "flex",
    },
    othersSectionTitle:{
        font: {
            size: 18,
        },
        margin: "10px 0"
    },
    otherSectionUnderline:{
        borderBottom:"4px solid green",
        width: 60,
    },
    otherText:{
        font: {
            size: 16,
            weight: 400
        },
    },
    otherSection:{
        textAlign: "left"
    },
    '@media (max-width: 500px)': {
        contentContainer:{
            flexDirection: "column",
        },
        descriptionSection:{
            width: "100%",
        },
        imageSection:{
            width: "100%",
        },
        otherSectionUnderline:{
            margin: "auto"
        },
        sectionTitle:{
            font: {
                size: 28,
            },
            margin: "10px 0"
        },
        descriptionText:{
            width: "100%",
            font: {
                size: 16,
                weight: 400
            }
        },
        sectionUnderline:{
            width: 100,
        },
        otherSection:{
            margin:"auto"
        },
    },
})

export default WhatWeDo;
