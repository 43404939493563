import React from 'react';
import { createUseStyles } from 'react-jss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faVenusMars, faUsers } from '@fortawesome/free-solid-svg-icons';
import { services } from './services';

library.add( faUser, faVenusMars, faUsers);
function Services(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
            <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>Our Services</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                {services.map((service, index) => {
                    const { name, description, image } = service;
                    const { src, alt } = image
                    return(
                        <div key={index} className={styles.itemContainer}>
                            <div className={styles.imageContainer}>
                                <img className={styles.image} src={src} alt={alt} />
                            </div>
                            <div>
                                <div className={styles.descriptionName}>
                                    <h4 className={styles.serviceName}>{name}</h4>
                                </div>
                                <div>
                                    <p className={styles.descriptionText}>{description}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>

        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        color: "black",
        height: "100%",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "80%",
        maxWidth: 1100,
        margin: "auto"
    },
    itemContainer:{
        width: 220,
        margin: "3%"
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        justifyContent: "center",
        alignItems: "center",
    },
    sectionTitle:{
        font: {
            size: 28,
        },
        margin: 10
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 90,
        margin: "auto"
    },
    descriptionName: {
        font: {
            size: 16,
        }
    },
    descriptionText: {
        font: {
            size: 12,
            weight: 400
        }
    },
    imageContainer:{
        width: 200,
        height: 200,
    },
    image:{
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        objectFit: "cover",
        backgroundSize: "cover"
    },
    serviceName: {
        font: {
            size: 20,
            weight: "600"
        },
        marginTop: 15
    },
    '@media (max-width: 500px)': {
        contentContainer:{
            width: "100%",
        },
        itemContainer: {
            width: 250,
        },
        descriptionText: {
            font: {
                size: 12,
                weight: 400
            }
        },
        imageContainer:{
            margin: "auto"
        },
    serviceName: {
        font: {
            size: 18,
            weight: "600"
        },
        marginTop: 15
    }
    },
})

export default Services;
