import React from 'react';
import { createUseStyles } from 'react-jss';
import { photos } from './photos';

function PhotoSection(){
    const useStyles = createUseStyles({
        container:{
            height: "100%",
            marginBottom: 30
        },
        sectionHeader:{
            display: "flex",
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2%"
        },
        sectionTitle:{
            font: {
                size: 28,
            },
            margin: 10
        },
        sectionUnderline:{
            borderBottom:"4px solid green",
            width: 90,
            margin: "auto"
        },
        contentContainer:{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            maxWidth: 1000,
            margin: "auto"
        },
        itemContainer:{
            margin: "2% 0",
            width: 310,
            height: 235
        },
        photo: {
            width: "100%",
            height: "100%",
            padding: 8,
            objectFit: "cover",
            background: "azure"
        },
        '@media (max-width: 800px)': {
            contentContainer:{
                width: "85%",
            },
        },
    })

    const styles = useStyles();

    return(
        <div className={styles.container}>
             <div className={styles.sectionHeader}>
                <div>
                    <h3 className={styles.sectionTitle}>Gallery</h3>
                    <div className={styles.sectionUnderline} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                {photos.map((photo, index) => {
                    const { src, alt } = photo;
                    return(
                        <div key={index} className={styles.itemContainer}>
                            <img className={styles.photo} src={src} alt={alt} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
};

export default PhotoSection
