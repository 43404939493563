import React from 'react';
import { createUseStyles } from 'react-jss';
import ContactSection from './Contact';
import PageHeader from '../Shared/PageHeader'

function ContactUsPage(){
    const styles = useStyles();
    return(
        <div className={styles.pageContainer}>
            <div className={styles.contactImageHeader}>
               <PageHeader pageTitle="Contact Us" />
            </div>
            <div className={styles.contactSection}>
                <ContactSection />
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    pageContainer: {
        position: "relative",
        minWidth: "100vw",
        height: "100%",
        width: "100%",
        fontFamily: "Open Sans, sans-serif",
        display: "flex",
        flexDirection: " column"
    },
    contactImageHeader:{

    },
    contactSection:{
        height:"fit-content",
    }
})

export default ContactUsPage;
