import React from 'react';
import { createUseStyles } from 'react-jss';

function OurMission(){
    const styles = useStyles();
    return(
        <div className={styles.container}>
            <div className={styles.itemContainer}>
                <div className={styles.sectionHeader}>
                    <div>
                        <h3 className={styles.sectionTitle}>Our Mission</h3>
                        <div className={styles.sectionUnderline} />
                    </div>
                </div>
                <div className={styles.contentContainer}>
                        <div className={styles.descriptionSection}>
                            <p className={styles.descriptionText}><strong className={styles.companyName}>We</strong> promote mental health and wellbeing for all Individuals, couples and groups by integrating methods that address the mind, body and spirit for personal growth and transformation.
                            </p>
                        </div>
                </div>
            </div>
            <div className={styles.itemContainer}>
                <div className={styles.sectionHeader}>
                    <div>
                        <h3 className={styles.sectionTitle}>Our Vision</h3>
                        <div className={styles.sectionUnderline} />
                    </div>
                </div>
                <div className={styles.contentContainer}>
                        <div className={styles.descriptionSection}>
                            <p className={styles.descriptionText}><strong className={styles.companyName}>To</strong> have a psychologically and mentally healthy society

                            </p>
                        </div>
                </div>
            </div>
        </div>
    )
} 

const useStyles = createUseStyles({
    container:{
        height: "100%",
        display: "flex",
        justifyContent: "center"
    },
    itemContainer:{
        width: "41%"
    },
    sectionHeader:{
        display: "flex",
        height: 100,
        width: "70%",
        margin: "auto",
        alignItems: "center",
    },
    sectionTitle:{
        font: {
            size: 22,
        },
        margin: "10px 0"
    },
    sectionUnderline:{
        borderBottom:"4px solid green",
        width: 50,
    },
    contentContainer:{
        display: "flex",
        justifyContent: "space-between",
        width: "70%",
        minHeight: "20vh",
        margin: "auto",
    },
    descriptionSection:{
        width: "65%",
    },
    descriptionText:{
        font: {
            size: 18,
            weight: 400
        }
    },
    companyName:{
        font: {
            size: 20,
        }
    },
    '@media (max-width: 500px)': {
        container:{
            flexDirection: "column",
        },
        itemContainer:{
            width: "100%"
        },
        contentContainer:{
            flexDirection: "column",
        },
        descriptionSection:{
            width: "100%",
        },
        sectionTitle:{
            font: {
                size: 28,
            },
            margin: "10px 0"
        },
        descriptionText:{
            font: {
                size: 16,
                weight: 400
            }
        },
        sectionUnderline:{
            width: 100,
        },
    },
})

export default OurMission;
